import { DomainChartContainer } from 'client/shared/containers/domain-chart-container';
import { getFilteredBenchmarkFooter } from 'client/shared/core/performance-data';
import { domainDescriptions, domainLabels } from 'client/shared/core/track/labels';
import {
  ActivationState,
  BenchmarkFilter,
  ChartType,
  MapExtentBounds,
  NationalDataFips,
  SafeRecordDictionary,
  StatisticType,
  TrackAreaMode,
  TrackDatumWithDistributions,
  TrackVariableWithDistributions,
  VariableDisplayType,
  VisualizationType,
  getMostRecentDatumCurrentPub,
  wrap,
} from 'core';
import * as React from 'react';
import { ContentUnavailableType } from '../content-unavailable';
import { DashboardCard } from '../dashboard-card';
import { DomainCardContent } from '../domain-card';
import { CommunityStatisticsCard } from '../domain-community-statistics-card';
import { ResidentSentiment } from '../resident-sentiment';
import { VisualizationUnavailable } from './not-found';
import { GroupIndexScoresContainer } from 'client/shared/containers/group-index-scores-container';
import { GroupIndicatorCard } from '../group-indicator-card';
import { CommunityStatisticVisualizationType } from 'client/admin/track/overview/containers/community-statistics-container';
import { MultiPolygon } from '@turf/helpers';

export enum EmbedDataPointContext {
  CONTENT_POST = 'CONTENT_POST',
  VISUALIZATION = 'VISUALIZATION',
}
export interface VisualizationData {
  readonly recordedAt: Date | null;
  readonly state: ActivationState;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly visualizationType: VisualizationType | null;
  readonly trackVariable: TrackVariableWithDistributions;
  readonly baseFips: string | null;
  readonly id: string;
  readonly comparisonGroupId?: string;
  readonly mapData: {
    readonly fipsAreasShapes: SafeRecordDictionary<string, MultiPolygon>;
    readonly mapBoundCoordinates?: MapExtentBounds;
  };
}
interface VisualizationPickerProps {
  readonly visualizationData: VisualizationData | null;
  readonly expanded: boolean;
  readonly postEmbed?: boolean;
  readonly toggleExpanded: () => void;
  readonly topLevelEmbed?: boolean;
  readonly label?: string;
  readonly showInactive?: boolean;
}

export const VisualizationPicker: React.FC<VisualizationPickerProps> = (props) => {
  const [chartType, setChartType] = React.useState<ChartType | null>(null);
  const useChartType: ChartType = wrap(() => {
    if (chartType) {
      return chartType;
    }
    if (props.visualizationData?.visualizationType) {
      switch (props.visualizationData.visualizationType) {
        case VisualizationType.HISTOGRAM:
          return ChartType.HISTOGRAM;
        case VisualizationType.LINE:
          return ChartType.LINE;
        case VisualizationType.MAP:
          return ChartType.MAP;
        default:
          return ChartType.HISTOGRAM;
      }
    }
    return ChartType.HISTOGRAM;
  });
  const [variableVisualizationType, setVariableVisualizationType] =
    React.useState<CommunityStatisticVisualizationType | null>(
      wrap(() => {
        switch (useChartType) {
          case ChartType.LINE:
            return CommunityStatisticVisualizationType.LINE;
          case ChartType.MAP:
            return CommunityStatisticVisualizationType.MAP;
          default:
            return null;
        }
      })
    );
  if (!props.visualizationData) {
    return (
      <VisualizationUnavailable
        label={props.label}
        type={ContentUnavailableType.NOT_FOUND}
      />
    );
  }
  const {
    state,
    benchmarkFilter,
    visualizationType,
    trackVariable: performanceData,
  } = props.visualizationData;

  const { areasData } = performanceData;

  // need to grab either the basefips from the array if there are multiple, or if theres only one we can assume its the basefips
  const currentFips = performanceData.areasData.find(
    (d) => d.fipsArea.id === props.visualizationData?.baseFips
  )?.fipsArea;
  const groupDoesNotContainPublisher = !!currentFips;

  const areaMode =
    currentFips?.id === NationalDataFips
      ? TrackAreaMode.NATIONAL
      : TrackAreaMode.LOCAL;

  const footer =
    areaMode === TrackAreaMode.LOCAL
      ? getFilteredBenchmarkFooter(benchmarkFilter)
      : null;

  if (state === ActivationState.INACTIVE && !props.showInactive) {
    return (
      <VisualizationUnavailable
        label={props.label}
        type={ContentUnavailableType.UNAVAILABLE}
      />
    );
  }

  const embedContext = wrap(() => {
    if (props.topLevelEmbed) {
      return EmbedDataPointContext.VISUALIZATION;
    } else if (props.postEmbed) {
      return EmbedDataPointContext.CONTENT_POST;
    } else return;
  });

  const latestPerformanceData = currentFips
    ? getMostRecentDatumCurrentPub<
        TrackDatumWithDistributions,
        TrackVariableWithDistributions
      >(currentFips, performanceData)
    : undefined;

  if (areasData.length > 1 && props.visualizationData.comparisonGroupId) {
    return props.visualizationData.trackVariable.statisticType ===
      StatisticType.INDEX ? (
      <DashboardCard
        areaMode={areaMode}
        benchmarkFilter={benchmarkFilter}
        currentFips={currentFips?.id}
        description={domainDescriptions[performanceData.domains[0].domain]}
        recentValue={latestPerformanceData}
        title={domainLabels[performanceData.domains[0].domain]}
        variable={performanceData}
      >
        <GroupIndexScoresContainer
          benchmarkFilter={benchmarkFilter}
          chartType={useChartType}
          comparisonGroupId={props.visualizationData.comparisonGroupId}
          currentFips={currentFips}
          displayType={VariableDisplayType.DEFAULT}
          domain={performanceData.domains[0].domain}
          embedContext={embedContext}
          fipsShapeByFipsCode={props.visualizationData.mapData.fipsAreasShapes}
          groupDoesNotContainPublisher={groupDoesNotContainPublisher}
          mapBoundCoordinates={props.visualizationData.mapData.mapBoundCoordinates}
          setChartType={
            ((type: ChartType | null) => {
              if (!type || type === useChartType) {
                setChartType(ChartType.HISTOGRAM);
              } else {
                setChartType(type);
              }
            }) as typeof setChartType
          }
          trackVariables={[performanceData]}
        />
      </DashboardCard>
    ) : (
      <GroupIndicatorCard
        benchmarkFilter={benchmarkFilter}
        comparisonGroupId={props.visualizationData.comparisonGroupId}
        currentFips={currentFips?.id}
        displayType={VariableDisplayType.DEFAULT}
        domain={performanceData.domains[0].domain}
        embedContext={embedContext}
        expanded={props.expanded}
        fipsShapeByFipsCode={props.visualizationData.mapData.fipsAreasShapes}
        indicator={performanceData}
        showFooter={!!performanceData.direction}
        toggleExpanded={props.toggleExpanded}
        toggleVisualization={(_varId, type) => {
          if (type === variableVisualizationType) {
            setVariableVisualizationType(null);
          } else {
            setVariableVisualizationType(type);
          }
        }}
        variableVisualizationType={variableVisualizationType}
      />
    );
  }

  if (!currentFips) {
    return (
      <VisualizationUnavailable
        label={props.label}
        type={ContentUnavailableType.NOT_FOUND}
      />
    );
  }

  switch (visualizationType) {
    case VisualizationType.LINE: {
      return performanceData.statisticType === StatisticType.INDICATOR ? (
        <CommunityStatisticsCard
          benchmarkFilter={benchmarkFilter}
          currentFips={currentFips}
          domain={performanceData.domains[0].domain}
          embedContext={embedContext}
          expanded={props.expanded}
          showFooter={
            areaMode === TrackAreaMode.LOCAL && !!performanceData.direction
          }
          toggleExpanded={props.toggleExpanded}
          variable={performanceData}
        />
      ) : (
        <DashboardCard
          areaMode={areaMode}
          benchmarkFilter={benchmarkFilter}
          chartTypeToSave={useChartType}
          currentFips={currentFips?.id}
          description={domainDescriptions[performanceData.domains[0].domain]}
          embedContext={embedContext}
          title={domainLabels[performanceData.domains[0].domain]}
        >
          <DomainCardContent
            domain={performanceData.domains[0].domain}
            indexScoreData={latestPerformanceData}
            variable={performanceData}
          />
          {footer && (
            <div className="font-size-sm py-2 mb-3 no-show-in-image">{footer}</div>
          )}
          <DomainChartContainer
            areaMode={areaMode}
            benchmarkFilter={benchmarkFilter}
            currentFips={currentFips}
            defaultChart={useChartType}
            indexScore={performanceData}
            setChart={setChartType}
          />
        </DashboardCard>
      );
    }
    case VisualizationType.HISTOGRAM: {
      return (
        <DashboardCard
          areaMode={areaMode}
          benchmarkFilter={benchmarkFilter}
          chartTypeToSave={useChartType}
          currentFips={currentFips?.id}
          description={domainDescriptions[performanceData.domains[0].domain]}
          embedContext={embedContext}
          title={domainLabels[performanceData.domains[0].domain]}
        >
          <DomainCardContent
            domain={performanceData.domains[0].domain}
            indexScoreData={latestPerformanceData}
            variable={performanceData}
          />
          {footer && (
            <div className="font-size-sm py-2 mb-3 no-show-in-image">{footer}</div>
          )}
          <DomainChartContainer
            areaMode={
              currentFips.id === NationalDataFips
                ? TrackAreaMode.NATIONAL
                : TrackAreaMode.LOCAL
            }
            benchmarkFilter={benchmarkFilter}
            currentFips={currentFips}
            defaultChart={useChartType}
            indexScore={performanceData}
            setChart={setChartType}
          />
        </DashboardCard>
      );
    }
    case VisualizationType.PROGRESS:
    default:
      return (
        <ResidentSentiment
          benchmarkFilter={benchmarkFilter}
          currentFips={currentFips}
          domain={performanceData.domains[0].domain}
          embedContext={embedContext}
          hideSaveAsImage
          hideTabBar
          sentimentValues={[performanceData]}
          showFooter={!!performanceData.direction}
          showOnlyTopSentimentValues={false}
        />
      );
  }
};
